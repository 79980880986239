<template>
	<layout :activeIndex="activeIndex" :activeModel.sync="activeModel" :topBarItems="topBarItems" @setIndex="setIndex">
		<template #left-0>
			<left-menu :list="list" @select="leftMenuSelect"></left-menu>
		</template>
		<template #left-1>
			<b-card>
				<label>起始时间</label>
				<b-form-input type="date"></b-form-input>
				<b-form-input id="start_time" class="mt-1" type="time"></b-form-input>

				<label class="mt-1">结束时间</label>
				<b-form-input type="date"></b-form-input>
				<b-form-input class="mt-1" type="time"></b-form-input>

				<label class="mt-1">车牌号</label>
				<b-form-input type="text"></b-form-input>

				<label class="mt-1">车库</label>
				<b-form-select v-model="selected_garage" :options="options_garage" value-field="text"></b-form-select>

				<div class="pt-2 text-right">
					<b-button variant="primary">筛选</b-button>
				</div>
			</b-card>
		</template>
		<template #right-0>
			<b-row>
				<b-col cols="5">
					<b-card>
						<b-card-text>
							<b-row class="d-flex align-items-center justify-content-between">
								<b-col cols="8">
									<div id="container1" style="height: 300px"></div>
								</b-col>
								<b-col cols="4">
									<div v-for="(item, index) in parking_data" :key="index" class="d-flex justify-content-between mt-50">
										<span>{{ item.text }}</span>
										<b-badge :variant="'light-' + item.color" style="font-size: 1.2rem">{{ item.num }}</b-badge>
									</div>
								</b-col>
							</b-row>
						</b-card-text>
					</b-card>
				</b-col>
				<b-col cols="7"></b-col>
			</b-row>
		</template>
		<template #right-1>
			<b-col cols="12">
				<b-card class="position-relative" no-body>
					<b-table :current-page="currentPage" :fields="fields_list" :items="items" :per-page="perPage" striped></b-table>
				</b-card>
				<b-pagination v-model="currentPage" :total-rows="rows" align="right" hide-goto-end-buttons next-class="next-item" prev-class="prev-item"></b-pagination>
			</b-col>
		</template>
		<template #right-2>
			<panel-group :show-title="false" group-uid="0fe7c568"></panel-group>
		</template>
	</layout>
</template>

<script>
	import Layout from "@/views/front/jsf35/subsystems/layout";
	import { onMounted, reactive, toRefs } from "@vue/composition-api";
	import { Pie } from "@antv/g2plot";
	import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";

	export default {
		name: "parking",
		components: {
			Layout,
			LeftMenu,
			panelGroup: () => import("@/views/panel-group"),
		},
		setup() {
			// 框架相关参数
			const layoutParams = reactive({
				activeIndex: 0,
				activeModel: "",
				topBarItems: [{ title: "主页" }, { title: "停车记录" }, { title: "数据分析" }],
			});
			//左侧菜单相关参数
			const leftMenuParams = reactive({
				list: [
					{ title: "B1", value: -1 },
					{ title: "B2", value: -2 },
					{ title: "B3", value: -3 },
					{ title: "B5", value: -5 },
				],
			});

			// 左侧菜单选中事件
			const leftMenuSelect = (event) => {
				console.log("leftMenuSelectEvent", event);
				// activeModel.value = event.model
			};

			const setIndex = (e) => {
				layoutParams.activeIndex = e;
			};

			const data = reactive({
				index: 0,
				rows: 1,
				parking_data: [
					{ text: "总车位", num: 324, color: "primary" },
					{ text: "占用", num: 156, color: "warning" },
					{ text: "空闲", num: 163, color: "success" },
					{ text: "不可用", num: 5, color: "secondary" },
				],
				status: [
					{
						text: "主页",
						active: true,
					},
					{
						text: "停车记录",
						active: false,
					},
				],
				list: [
					{ text: "车牌号", value: "" },
					{ text: "停车位置", value: "" },
					{ text: "进场时间", value: "" },
					{ text: "离场时间", value: "" },
					{ text: "停车时长", value: "" },
				],
				fields_list: [
					{ key: "index", label: "ID" },
					{ key: "license_plate", label: "车牌号" },
					{ key: "approach_time", label: "进场时间" },
					{ key: "departure", label: "离场时间" },
					{ key: "duration", label: "停车时长" },
					{ key: "paytime", label: "付款时间" },
					{ key: "amount_paid", label: "付款金额" },
					{ key: "garage", label: "所处车库" },
				],
				items: [
					{
						index: "1",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
					{
						index: "2",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
					{
						index: "3",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
					{
						index: "4",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},

					{
						index: "5",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
					{
						index: "6",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
					{
						index: "7",
						license_plate: "京A 123456",
						approach_time: "2022-02-22 09:15:00",
						departure: "2022-02-22 18:15:00",
						duration: "2:10:20",
						paytime: "2022-02-22 18:15:00",
						amount_paid: "¥ 50.00",
						garage: "B1",
					},
				],
				perPage: 15,
				currentPage: 1,
				floor_list: ["B1", "B2", "B3", "B5"],
				selected_garage: "全部",
				options_garage: ["全部", "B1", "B2", "B3", "B5"],
			});
			const plot = () => {
				const data = [
					{ type: "占用", value: 48.15 },
					{ type: "空闲", value: 50.31 },
					{ type: "不可用", value: 1.54 },
				];
				const piePlot = new Pie("container1", {
					appendPadding: 10,
					data,
					angleField: "value",
					colorField: "type",
					radius: 0.9,
					innerRadius: 0.6,
					legend: {
						position: "bottom",
					},
					tooltip: {
						formatter: (datum) => {
							return {
								name: datum.type,
								value: datum.value + "%",
							};
						},
					},
					label: {
						type: "spider",
						labelHeight: 28,
						content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
						style: {
							textAlign: "center",
							fontSize: 14,
						},
					},
					interactions: [
						//   { type: "element-selected" },
						{ type: "element-active" },
					],
					statistic: {
						title: false,
						content: {
							style: {
								whiteSpace: "pre-wrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							},
							content: "",
						},
					},
				});

				piePlot.render();
			};
			onMounted(() => {
				plot();
			});
			return {
				...toRefs(layoutParams),
				...toRefs(leftMenuParams),
				leftMenuSelect,
				setIndex,
				...toRefs(data),
			};
		},
	};
</script>
<style scoped></style>
